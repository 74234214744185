import { Link, useLocation } from '@remix-run/react';
import Sprite from '../../sprites/sprite';
import { FACEBOOK, INSTAGRAM, TWITTER, YOUTUBE } from '../../constants/socials';
import React from 'react';
import { useMessages } from '../../i18n/useMessages';
// import { statsigState } from '../../state/statsig';
import clsx from 'clsx';

interface BannerProps {
  onOpenInquiry: VoidFunction;
}

export const AVOID_LIST = [
  '/packages/reservation',
  '/packages/reservation/complete-payment',
  '/packages/reservation/complete-payment-paypal',
];

export const Banner: React.FC<BannerProps> = ({ onOpenInquiry }) => {
  const location = useLocation();
  const getMessage = useMessages();
  const inquireNow = getMessage('camp67-banner.inquire-now');
  const bookNow = getMessage('camp67-banner.book-now');

  if (AVOID_LIST.includes(location.pathname)) {
    return null;
  }

  const isInquiryExperimentEnabled = false;

  return (
    <div
      id="camp67-banner"
      className="w-full z-30 bg-white flex flex-row items-center fixed shadow-md shadow-black/5 top-0 left-0 py-1 px-6 sm:px-2 md:px-24 lg:px-56"
    >
      <ul className="w-full flex flex-row text-sm items-center">
        <li className="hidden sm:flex flex-row items-center md:-ml-16 lg:-ml-32">
          <Sprite name="email" width="17" height="16" />
          <span className="ml-1.5">bookings@camp67.com</span>
        </li>
        <li className="flex flex-row items-center">
          <span className="ml-1.5 flex flex-row flex-wrap items-center whitespace-nowrap gap-1">
            <span className="flex flex-row items-center gap-1">
              <Sprite name="phone" width="17" height="16" />
              +91 8065249725{' '}
            </span>
            <span className="flex flex-row items-center gap-1">
              <img
                src="/whatsapp.png"
                alt="Whatsapp button"
                className="w-6 h-6 shadow-lg rounded-full pointer-events-none select-none"
              />{' '}
              +91 8485835675
            </span>
          </span>
        </li>
      </ul>
      <div className="flex flex-row h-full items-center gap-6">
        <div className="flex flex-row items-center gap-1">
          <button
            onClick={onOpenInquiry}
            className={clsx(
              'text-sm bg-[#D4D400]/40 hover:bg-[#D4D400]/25 transition px-3 py-1 rounded-md whitespace-nowrap',
              {
                'hidden xs:block': !isInquiryExperimentEnabled,
              },
            )}
          >
            {inquireNow}
          </button>
          <Link to="/packages/reservation" prefetch="intent">
            <button
              className={clsx(
                'text-sm bg-orange-150 hover:bg-[#D4D400]/75 transition px-3 py-1 rounded-md whitespace-nowrap',
                {
                  'hidden xs:block': isInquiryExperimentEnabled,
                },
              )}
            >
              {bookNow}
            </button>
          </Link>
        </div>
        <div className="hidden sm:block w-0.5 h-9 border-r border-r-black border-dotted" />
        <ul className="hidden md:flex flex-row items-center">
          <a href={FACEBOOK} target="_blank" rel="noreferrer">
            <li className="cursor-pointer hover:opacity-75 transition">
              <Sprite
                name="facebook"
                width="64"
                height="64"
                fill="#D4D400"
                className="min-w-6 w-6 min-h-6 h-6"
              />
            </li>
          </a>
          <a href={INSTAGRAM} target="_blank" rel="noreferrer">
            <li className="cursor-pointer hover:opacity-75 transition">
              <img
                src="/instagram.png"
                alt="Instagram logo"
                className="min-w-6 min-h-6"
              />
            </li>
          </a>
          <a href={TWITTER} target="_blank" rel="noreferrer">
            <li className="cursor-pointer hover:opacity-75 transition">
              <Sprite
                name="twitter"
                width="64"
                height="64"
                fill="#D4D400"
                className="min-w-6 w-6 min-h-6 h-6"
              />
            </li>
          </a>
          <a href={YOUTUBE} target="_blank" rel="noreferrer">
            <li className="cursor-pointer hover:opacity-75 transition">
              <Sprite
                name="youtube"
                width="64"
                height="45"
                fill="#D4D400"
                className="min-w-6 w-6 min-h-6 h-6"
              />
            </li>
          </a>
        </ul>
      </div>
    </div>
  );
};
