import { images } from '../constants/images';
import { Page } from './pages-types';
import { ChildPagePreview } from './child-page-preview';
import { PageRoutes } from './page-routes';
import { tents } from '../constants/tents';
import { TentTypeV1 } from '@camp67/model';
import { MessageKey } from '../locales/en';
import { FormattedString } from '../i18n/useMessages';

export type ChildPage = Pick<Page, 'path' | 'readingTime' | 'title' | 'summary'>;

const tentMessageKeyFromTentType = (tentType: TentTypeV1): MessageKey => {
  switch (tentType) {
    case TentTypeV1.TWIN_ROOM: {
      return 'camp67-navigation.packages.twin-room';
    }
    // case TentTypeV1.PREMIUM_TWIN_COTTAGE: {
    //   return 'camp67-navigation.packages.premium-twin-room';
    // }
    case TentTypeV1.FAMILY_ROOM: {
      return 'camp67-navigation.packages.family-room';
    }
    // case TentTypeV1.FAMILY_ROOM_PLUS: {
    //   return 'camp67-navigation.packages.family-room-plus';
    // }
    case TentTypeV1.DORM_SINGLE: {
      return 'camp67-navigation.packages.dormitory-single';
    }
    case TentTypeV1.PRIVATE_GROUP_COTTAGE: {
      return 'camp67-navigation.packages.private-group-cottage';
    }
    case TentTypeV1.DORM_BUNK: {
      return 'camp67-navigation.packages.dormitory-bunk';
    }
  }
};

export const tentSummaryFromTentType = (tentType: TentTypeV1) => {
  switch (tentType) {
    case TentTypeV1.TWIN_ROOM: {
      return (
        <FormattedString
          message="camp67-tent-page.twin-room-summary"
          components={{ paragraph: <p className="mt-3" /> }}
        />
      );
    }
    // case TentTypeV1.PREMIUM_TWIN_COTTAGE: {
    //   return (
    //     <FormattedString
    //       message="camp67-tent-page.premium-twin-room-summary"
    //       components={{ paragraph: <p className="mt-3" /> }}
    //     />
    //   );
    // }
    case TentTypeV1.FAMILY_ROOM: {
      return (
        <FormattedString
          message="camp67-tent-page.family-room-summary"
          components={{ paragraph: <p className="mt-3" /> }}
        />
      );
    }
    // case TentTypeV1.FAMILY_ROOM_PLUS: {
    //   return (
    //     <FormattedString
    //       message="camp67-tent-page.family-room-plus-summary"
    //       components={{ paragraph: <p className="mt-3" /> }}
    //     />
    //   );
    // }
    case TentTypeV1.DORM_SINGLE: {
      return <FormattedString message="camp67-tent-page.dorm-single-summary" />;
    }
    case TentTypeV1.PRIVATE_GROUP_COTTAGE: {
      return <FormattedString message="camp67-tent-page.private-group-cottage-summary" />;
    }
    case TentTypeV1.DORM_BUNK: {
      return (
        <FormattedString
          message="camp67-tent-page.dorm-bunk-summary"
          components={{ paragraph: <p className="mt-3" /> }}
        />
      );
    }
  }
};

export const packagesPageChildren: ChildPage[] = [
  {
    title: 'camp67-navigation.packages.reserve-a-room',
    readingTime: 0,
    path: PageRoutes.reservation,
    summary: <FormattedString message="camp67-packages-page-children.summary" />,
  },
  ...Object.values(tents).map(
    (tent): ChildPage => ({
      path: tent.path,
      readingTime: 2,
      title: tentMessageKeyFromTentType(tent.tentType),
      summary: tentSummaryFromTentType(tent.tentType),
    }),
  ),
];

export const packagesPage: Page = {
  title: 'camp67-navigation.packages',
  subtitle: 'camp67-packages-page.subtitle',
  summary: <FormattedString message="camp67-packages-page.summary" />,
  path: PageRoutes.packages,
  heroImage: images['who-are-we-hero-v2'],
  heroImageAlt: 'camp67-packages-page.hero-image-alt',
  readingTime: packagesPageChildren.reduce(
    (acc, { readingTime }) => acc + readingTime,
    0,
  ),
  maxWidth: true,
  content: (
    <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
      {packagesPageChildren.map((props, index) => (
        <ChildPagePreview key={index} {...props} />
      ))}
    </div>
  ),
};
