export const contactMessages = {
  'camp67-contact.contact-us': 'Contact us',
  'camp67-contact.offered-by': 'camp67 is offered by',
  'camp67-contact.address': 'Head Office Address',
  'camp67-contact.campsite-address': 'Campsite Address',
  'camp67-contact.questions': 'For any questions or concerns, please contact us at:',
  'camp67-contact.email': 'Email',
  'camp67-contact.helpdesk': 'Helpdesk No.',
  'camp67-contact.whatsapp-only': 'For WhatsApp message only',
  'camp67-contact.outside-india': 'From outside India',
  'camp67-contact.phone': 'Phone',
  'camp67-contact.see-tos': 'See our Terms of Service',
  'camp67-contact.map': 'See on map',
};
