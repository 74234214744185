import { TentTypeV1 } from '@camp67/model';
import { getBathingPrice, getPrice } from '@camp67/model/booking/price';

export const getLowestPricedTentPrice = () => {
  return getPrice(
    Object.values(TentTypeV1).reduce((lowest, tentType) => {
      const price = getPrice(tentType);
      return price < getPrice(lowest) ? tentType : lowest;
    }),
  );
};

export const getLowestPricedTent = () => {
  return Object.values(TentTypeV1).reduce((lowest, tentType) => {
    const price = getPrice(tentType);
    return price < getPrice(lowest) ? tentType : lowest;
  });
};

export const getLowestPriceTag = () => {
  const tentType = getLowestPricedTent();
  return getPriceTag(tentType);
};

export const getPriceTag = (tentType: TentTypeV1, includeRsPrefix = true) => {
  const price = getPrice(tentType);
  return `${includeRsPrefix ? 'RS. ₹' : '₹'}${price.toLocaleString()}`;
};

export const getBathingPriceTag = (tentType: TentTypeV1, includeRsPrefix = true) => {
  const price = getBathingPrice(tentType);
  return `${includeRsPrefix ? 'RS. ₹' : '₹'}${price.toLocaleString()}`;
};
