import { TentTypeV1, TentTypeV2 } from '@camp67/model';
import {
  CapacityCount,
  RoomCount,
  capacityCount,
  roomCount,
} from './booking-configuration-types';

export const capacityV1 = new Map<TentTypeV1 | TentTypeV2, CapacityCount>([
  [TentTypeV1.TWIN_ROOM, capacityCount(2)],
  [TentTypeV1.FAMILY_ROOM, capacityCount(4)],
  [TentTypeV1.DORM_SINGLE, capacityCount(1)],
  [TentTypeV1.DORM_BUNK, capacityCount(1)],
  [TentTypeV2.PREMIUM_TWIN_COTTAGE, capacityCount(2)],
  [TentTypeV2.MUDHOUSE, capacityCount(4)],
  [TentTypeV2.MUDHOUSE_SUITE, capacityCount(4)],
  [TentTypeV1.PRIVATE_GROUP_COTTAGE, capacityCount(8)],
]);

export const inventoryV1 = new Map<TentTypeV1 | TentTypeV2, RoomCount>([
  [TentTypeV1.TWIN_ROOM, roomCount(24)],
  [TentTypeV1.FAMILY_ROOM, roomCount(60)],
  [TentTypeV1.DORM_SINGLE, roomCount(48)],
  [TentTypeV1.DORM_BUNK, roomCount(64)],
  [TentTypeV2.MUDHOUSE, roomCount(21)],
  [TentTypeV2.MUDHOUSE_SUITE, roomCount(6)],
  [TentTypeV2.PREMIUM_TWIN_COTTAGE, roomCount(30)],
  [TentTypeV1.PRIVATE_GROUP_COTTAGE, roomCount(75)],
]);
