export const banner = {
  'camp67-banner.inquire-now': 'Inquire now',
  'camp67-banner.book-now': 'Book now',
};

export const navigation = {
  'camp67-navigation.the-camp': 'The Camp',
  'camp67-navigation.camp67': 'camp67',
  'camp67-navigation.camp67.who-are-we': 'Who are we?',
  'camp67-navigation.camp67.camp67-&-kumbh': 'camp67 & Kumbh',
  'camp67-navigation.camp67.camp67-&-the-land': 'camp67 & the Land',
  'camp67-navigation.kumbh-2025': 'Kumbh 2025',
  'camp67-navigation.kumbh-2025.significance-of-kumbh': 'Significance of Kumbh',
  'camp67-navigation.kumbh-2025.paush-purnima': 'Paush Purnima (Jan 13)',
  'camp67-navigation.kumbh-2025.makar-sankranti': 'Makar Sankranti (Jan 14)',
  'camp67-navigation.kumbh-2025.mauni-amavasya': 'Mauni Amavasya (Jan 29)',
  'camp67-navigation.kumbh-2025.basant-panchami': 'Basant Panchami (Feb 3)',
  'camp67-navigation.kumbh-2025.achal-saptami': 'Achal Saptami (Feb 4)',
  'camp67-navigation.kumbh-2025.maghi-purnima': 'Maghi Purnima (Feb 12)',
  'camp67-navigation.kumbh-2025.maha-shivratri': 'Maha Shivratri (Feb 26)',
  'camp67-navigation.packages': 'Packages',
  'camp67-navigation.packages.reserve-a-room': 'Reserve a room',
  'camp67-navigation.packages.twin-room': 'Twin Bed Cottage',
  'camp67-navigation.packages.premium-twin-room': 'Premium Cottage',
  'camp67-navigation.packages.family-room': 'Family Cottage',
  'camp67-navigation.packages.family-room-plus': 'Family Cottage Plus',
  'camp67-navigation.packages.dormitory-single': 'Dormitory (Single)',
  'camp67-navigation.packages.private-group-cottage': 'Private Group Cottage',
  'camp67-navigation.packages.dormitory-bunk': 'Dormitory (Bunk)',
  'camp67-navigation.contact-us': 'Contact us',
};

export const camp67Header = {
  'camp67-header.tagline': 'Affordable. Peaceful. Uniquely Kumbh.',
  'camp67-header.typewriter.prefix': 'Stay at the most ',
  'camp67-header.typewriter.serene': 'serene',
  'camp67-header.typewriter.peaceful': 'peaceful',
  'camp67-header.typewriter.safe': 'safe',
  'camp67-header.typewriter.secure': 'secure',
  'camp67-header.typewriter.hygienic': 'hygienic',
  'camp67-header.typewriter.affordable': 'affordable',
  'camp67-header.typewriter.suffix.start': 'campsite of',
  'camp67-header.typewriter.suffix.end': 'Kumbh 2025.',
  'camp67-header.reserve-your-spot.prefix.desktop': `Book now for`,
  'camp67-header.reserve-your-spot.prefix.mobile': `Book now for`,
  'camp67-header.reserve-your-spot.secure-checkout': `Secure checkout with:`,
  'camp67-header.see-prices': 'Browse offerings',
  'camp67-header.inquiry': 'Make an inquiry',
};

export const links = {
  'camp67-link.sukrit-wellness-tours': 'Sukrit Wellness Tours →',
  'camp67-link.kumbh-sukrit-camp': 'Kumbh Sukrit Camp →',
  'camp67-link.see-more-on': 'See more on',
  'camp67-link.learn-more': 'Learn more',
  'camp67-link.terms-of-service': 'Terms of Service',
  'camp67-link.privacy-policy': 'Privacy Policy',
  'camp67-link.payment-policy': 'Payment Policy',
  'camp67-link.cancellation-policy': 'Cancellation Policy',
};

export const common = {
  'camp67.submit': 'Submit',
  'camp67.reset': 'Reset',
  'camp67.continue': 'Payment →',
  'camp67.go-back': 'Go back',
  'camp67.ssl': 'Secured via SSL and browser security policies.',
};

export const footer = {
  'camp67-footer.stay-informed': `<strong>Stay informed.</strong> We'll keep you posted on any discounts and sales. No spam!`,
  'camp67-footer.email.placeholder-subtle': 'Your email...',
  'camp67-footer.email.placeholder':
    'Enter your email address here. E.g., ilovecamp67@gmail.com...',
  'camp67-footer.subscribe-for-updates': 'Subscribe for updates',
  'camp67-footer.camp67-summary':
    'A part of <swt-link>Sukrit Wellness Tours</swt-link>, camp67 is from the team behind <ksc-link>Kumbh Sukrit Camp</ksc-link>. Affiliated with the Ministry of Tourism and Incredible India, we have been hosting Kumbh for 10+ years. <join-us-link >Join us</join-us-link> in 2025.',
  'camp67-footer.a-product-of': 'A product of',
  'camp67-footer.subscribe-success': `You're subscribed!`,
  'camp67-footer.subscribe-success-message': `Thank you for subscribing to our latest updates and news. We hope you're as excited as we are for what's to come!`,
  'camp67-footer.subscribe-opt-out': 'You may opt-out at any time from this subcription.',
};
