import { AmenityProps } from '../components/amenity';
import { MessageKey } from '../locales/en';
import { FormattedString } from '../i18n/useMessages';
import { TentTypeV1 } from '@camp67/model';

interface TentAmenityDetailProps {
  details: MessageKey[];
}

const TentAmenityDetail = ({ details }: TentAmenityDetailProps) => {
  return (
    <ul className="text-left list-disc pl-4">
      {details.map((d, i) => (
        <li key={i}>
          <FormattedString message={d} />
        </li>
      ))}
    </ul>
  );
};

export const twinRoomAmenities: AmenityProps[] = [
  {
    summary: 'camp67-tent-amenity.comfortable-living',
    detail: (
      <TentAmenityDetail
        details={[
          'camp67-tent-amenity.comfortable-living.single-beds',
          'camp67-tent-amenity.comfortable-living.quilt-blanket-pillows',
          'camp67-tent-amenity.comfortable-living.side-table-per-bed',
          // 'camp67-tent-amenity.comfortable-living.centre-table',
          'camp67-tent-amenity.comfortable-living.side-table',
        ]}
      />
    ),
  },
  {
    summary: 'camp67-tent-amenity.toiletries',
    detail: (
      <TentAmenityDetail
        details={[
          'camp67-tent-amenity.toiletries.private-bathroom',
          'camp67-tent-amenity.toiletries.bath-towel-single',
          'camp67-tent-amenity.toiletries.small-dustbin',
        ]}
      />
    ),
  },
  {
    summary: 'camp67-tent-amenity.power-storage',
    detail: (
      <TentAmenityDetail
        details={[
          'camp67-tent-amenity.power-storage.electric-power-point',
          'camp67-tent-amenity.power-storage.luggage-storage-racks',
        ]}
      />
    ),
  },
  {
    summary: 'camp67-tent-amenity.food-beverage',
    detail: (
      <TentAmenityDetail
        details={[
          'camp67-tent-amenity.food-beverage.drinking-water',
          // No longer offered in the camp
          // 'camp67-tent-amenity.food-beverage.tea-biscuits',
          'camp67-tent-amenity.food-beverage.complimentary-meals',
        ]}
      />
    ),
  },
  // This is no longer offered in the camp
  // {
  //   summary: 'camp67-tent-amenity.complimentary',
  //   detail: (
  //     <TentAmenityDetail details={['camp67-tent-amenity.complimentary.souvenir']} />
  //   ),
  // },
];

export const familyRoomAmenities: AmenityProps[] = [
  {
    summary: 'camp67-tent-amenity.comfortable-living',
    detail: (
      <TentAmenityDetail
        details={[
          'camp67-tent-amenity.comfortable-living.quilt-blanket-pillows',
          'camp67-tent-amenity.comfortable-living.side-table-per-bed',
          'camp67-tent-amenity.comfortable-living.side-table-glasses',
        ]}
      />
    ),
  },
  {
    summary: 'camp67-tent-amenity.toiletries',
    detail: (
      <TentAmenityDetail
        details={[
          'camp67-tent-amenity.toiletries.private-bathroom',
          'camp67-tent-amenity.toiletries.bath-towel-single',
          'camp67-tent-amenity.toiletries.small-dustbin',
        ]}
      />
    ),
  },
  {
    summary: 'camp67-tent-amenity.power-storage',
    detail: (
      <TentAmenityDetail
        details={[
          'camp67-tent-amenity.power-storage.electric-power-point',
          'camp67-tent-amenity.power-storage.luggage-storage-racks',
        ]}
      />
    ),
  },
  {
    summary: 'camp67-tent-amenity.food-beverage',
    detail: (
      <TentAmenityDetail
        details={[
          'camp67-tent-amenity.food-beverage.drinking-water',
          // No longer offered in the camp
          // 'camp67-tent-amenity.food-beverage.lemongrass-tea',
          'camp67-tent-amenity.food-beverage.complimentary-meals',
        ]}
      />
    ),
  },
  // This is no longer offered in the camp
  // {
  //   summary: 'camp67-tent-amenity.complimentary',
  //   detail: (
  //     <TentAmenityDetail details={['camp67-tent-amenity.complimentary.souvenir']} />
  //   ),
  // },
];

export const familyPlusRoomAmenities: AmenityProps[] = [
  {
    summary: 'camp67-tent-amenity.comfortable-living',
    detail: (
      <TentAmenityDetail
        details={[
          'camp67-tent-amenity.comfortable-living.two-queen-beds',
          'camp67-tent-amenity.comfortable-living.quilt-blanket-pillows',
          'camp67-tent-amenity.comfortable-living.side-table-per-bed',
          'camp67-tent-amenity.comfortable-living.centre-table',
          'camp67-tent-amenity.comfortable-living.sofa-chairs',
          'camp67-tent-amenity.comfortable-living.side-table-kettle',
          'camp67-tent-amenity.comfortable-living.table-lamp',
        ]}
      />
    ),
  },
  {
    summary: 'camp67-tent-amenity.toiletries',
    detail: (
      <TentAmenityDetail
        details={[
          'camp67-tent-amenity.toiletries.private-bathroom',
          'camp67-tent-amenity.toiletries.bath-towel',
          'camp67-tent-amenity.toiletries.sukrit-soaps-lotion',
          'camp67-tent-amenity.toiletries.small-dustbin',
        ]}
      />
    ),
  },
  {
    summary: 'camp67-tent-amenity.power-storage',
    detail: (
      <TentAmenityDetail
        details={[
          'camp67-tent-amenity.power-storage.electric-power-point-2x',
          'camp67-tent-amenity.power-storage.luggage-storage-racks',
        ]}
      />
    ),
  },
  {
    summary: 'camp67-tent-amenity.food-beverage',
    detail: (
      <TentAmenityDetail
        details={[
          'camp67-tent-amenity.food-beverage.drinking-water',
          'camp67-tent-amenity.food-beverage.lemongrass-tea-biscuits',
          'camp67-tent-amenity.food-beverage.complimentary-meals',
        ]}
      />
    ),
  },
  {
    summary: 'camp67-tent-amenity.complimentary',
    detail: (
      <TentAmenityDetail details={['camp67-tent-amenity.complimentary.souvenir']} />
    ),
  },
];

type Dorm =
  | TentTypeV1.DORM_BUNK
  | TentTypeV1.DORM_SINGLE
  | TentTypeV1.PRIVATE_GROUP_COTTAGE;

export const dormAmenities: (type: Dorm) => AmenityProps[] = (type: Dorm) => [
  {
    summary: 'camp67-tent-amenity.comfortable-living',
    detail: (
      <TentAmenityDetail
        details={[
          type === TentTypeV1.DORM_SINGLE
            ? 'camp67-tent-amenity.comfortable-living.single-bed'
            : 'camp67-tent-amenity.comfortable-living.bunk-bed',
          'camp67-tent-amenity.comfortable-living.quilt-blanket-pillows',
        ]}
      />
    ),
  },
  {
    summary: 'camp67-tent-amenity.toiletries',
    detail: (
      <TentAmenityDetail
        details={
          [
            'camp67-tent-amenity.toiletries.shared-toilets-dorm',
            type === TentTypeV1.PRIVATE_GROUP_COTTAGE
              ? 'camp67-tent-amenity.toiletries.bath-towel'
              : undefined,
          ].filter((d) => d !== undefined) as MessageKey[]
        }
      />
    ),
  },
  {
    summary: 'camp67-tent-amenity.food-beverage',
    detail: (
      <TentAmenityDetail
        details={['camp67-tent-amenity.food-beverage.complimentary-meals']}
      />
    ),
  },
];
