import { differenceInDays } from 'date-fns/differenceInDays';
import {
  BookingItem,
  TentTypeV1,
  TentTypeV1Prices,
  TentTypeV2,
  TentTypeV2Prices,
} from '..';
import { getBathingDaysInRange, parseDate } from './booking-configuration-dates';
import { capacityV1 } from './booking-configuration-v1';
import { CapacityCount } from './booking-configuration-types';

export function getPrice(tentType: TentTypeV1 | TentTypeV2) {
  switch (tentType) {
    case TentTypeV1.TWIN_ROOM:
      return TentTypeV1Prices.TWIN_ROOM;
    case TentTypeV1.FAMILY_ROOM:
      return TentTypeV1Prices.FAMILY_ROOM;
    case TentTypeV1.DORM_SINGLE:
      return TentTypeV1Prices.DORM_SINGLE;
    case TentTypeV1.DORM_BUNK:
      return TentTypeV1Prices.DORM_BUNK;
    case TentTypeV1.PRIVATE_GROUP_COTTAGE:
      return TentTypeV1Prices.PRIVATE_GROUP_COTTAGE;
    case TentTypeV2.PREMIUM_TWIN_COTTAGE:
      return TentTypeV2Prices.PREMIUM_TWIN_COTTAGE;
    case TentTypeV2.MUDHOUSE:
      return TentTypeV2Prices.MUDHOUSE;
    case TentTypeV2.MUDHOUSE_SUITE:
      return TentTypeV2Prices.MUDHOUSE_SUITE;
  }
}

export const bathingPremium = 500;
// 20% addition
export const bathingPremiumFactor = 0.2;

export const getBathingPrice = (tentType: TentTypeV1 | TentTypeV2) => {
  if (tentType === TentTypeV2.PREMIUM_TWIN_COTTAGE) {
    return 9625;
  }

  if (tentType === TentTypeV1.TWIN_ROOM) {
    return 6250;
  }

  if (tentType === TentTypeV1.FAMILY_ROOM) {
    return 5250;
  }

  if (tentType === TentTypeV1.PRIVATE_GROUP_COTTAGE) {
    return 3080;
  }

  if (tentType === TentTypeV1.DORM_SINGLE) {
    return 3080;
  }

  if (tentType === TentTypeV1.DORM_BUNK) {
    return 2100;
  }

  if (tentType === TentTypeV2.MUDHOUSE) {
    return TentTypeV2Prices.MUDHOUSE;
  }

  if (tentType === TentTypeV2.MUDHOUSE_SUITE) {
    return TentTypeV2Prices.MUDHOUSE_SUITE;
  }

  return getPrice(tentType) * (1 + bathingPremiumFactor);
};

export const getLowestPricedTent = () => {
  return Object.values(TentTypeV1).reduce((lowest, tentType) => {
    const price = getPrice(tentType);
    return price < getPrice(lowest) ? tentType : lowest;
  });
};

export const getLowestPriceTag = () => {
  const tentType = getLowestPricedTent();
  return getPriceTag(tentType);
};

export const getPriceTag = (tentType: TentTypeV1) => {
  const price = getPrice(tentType);
  return `RS. ₹${price.toLocaleString()}`;
};

export const getBathingPriceTag = (tentType: TentTypeV1) => {
  const price = getBathingPrice(tentType);
  return `RS. ₹${price.toLocaleString()}`;
};

export type PriceComputation = {
  total: number;
  totalGst: number;
  totalWithGst: number;
  totalFromNonBathingDays: number;
  totalFromBathingDays: number;
  nights: number;
  nightsBathing: number;
};

export const calculatePrice = (
  startDate: string,
  endDate: string,
  rooms: BookingItem[],
): PriceComputation => {
  const start = parseDate(startDate);
  const end = parseDate(endDate);

  const bathingDaysCount = getBathingDaysInRange(startDate, endDate).length;
  const nonBathingDaysCount = differenceInDays(end, start);

  let totalFromBathingDays = 0;
  let totalFromNonBathingDays = 0;
  for (const [type, count] of rooms) {
    let maxCapacityOfRoom = capacityV1.get(type) as CapacityCount;
    if (type === TentTypeV2.MUDHOUSE || type === TentTypeV2.MUDHOUSE_SUITE) {
      // Mudhouses have fixed prices, price should not change based on guests attending
      maxCapacityOfRoom = 1 as CapacityCount;
    }

    totalFromBathingDays +=
      getBathingPrice(type) * count * bathingDaysCount * maxCapacityOfRoom;
    totalFromNonBathingDays +=
      getPrice(type) *
      count *
      Math.abs(nonBathingDaysCount - bathingDaysCount) *
      maxCapacityOfRoom;
  }

  const total = totalFromNonBathingDays + totalFromBathingDays;
  const totalGst = total * 0.05;

  return {
    total,
    totalGst,
    totalWithGst: total + totalGst,
    totalFromNonBathingDays,
    totalFromBathingDays,
    nights: nonBathingDaysCount,
    nightsBathing: bathingDaysCount,
  };
};
