import { TentCardProps } from '../components/tent-card';
import React from 'react';

import { AmenityProps, amenities as campAmenities } from '../components/amenity';
import { PageRoutes } from '../pages/page-routes';
import { getPriceTag, getBathingPriceTag } from '../state/get-tent-price';
import {
  twinRoomAmenities,
  familyRoomAmenities,
  // familyPlusRoomAmenities,
  dormAmenities,
} from './amenities';
import { images } from './images';
import { TentTypeV1 } from '@camp67/model';
import { MessageKey } from '../locales/en';
// import { FormattedString } from '../i18n/useMessages';

// Sets up for any other info we need like text on photo, etc
interface GalleryItem {
  imageUrl: string;
}

export interface Tent extends Omit<TentCardProps, 'onClick'> {
  amenities: { tentAmenities: AmenityProps[]; campAmenities: AmenityProps[] };
  gallery: GalleryItem[];
  title: MessageKey;
  subtitle: MessageKey;
  description: MessageKey;
  guests: MessageKey;
  displayName?: React.ReactNode;
  people: number;
  isPrivate: boolean;
}

export const tents: {
  [K in TentTypeV1]: Tent;
} = {
  // [TentTypeV1.PREMIUM_TWIN_COTTAGE]: {
  //   tentType: TentTypeV1.PREMIUM_TWIN_COTTAGE,
  //   imageUrl: images['twin-tent-v2-1'],
  //   title: 'camp67-accommodation.premium-twin-room.title',
  //   subtitle: 'camp67-accommodation.premium-twin-room.subtitle',
  //   description: 'camp67-accommodation.premium-twin-room.description',
  //   imageAlt: 'camp67-accommodation.premium-twin-room.image-alt',
  //   guests: 'camp67-accommodation.premium-twin-room.guests',
  //   people: 2,
  //   price: getPriceTag(TentTypeV1.PREMIUM_TWIN_COTTAGE, false),
  //   bathingPrice: getBathingPriceTag(TentTypeV1.PREMIUM_TWIN_COTTAGE, false),
  //   amenities: {
  //     tentAmenities: twinRoomAmenities.map((prev) => ({ ...prev, open: undefined })),
  //     campAmenities: campAmenities.map((prev) => ({ ...prev, open: undefined })),
  //   },
  //   gallery: [
  //     {
  //       imageUrl: images['premium-twin-tent-1'],
  //     },
  //     {
  //       imageUrl: images['premium-twin-tent-2'],
  //     },
  //     {
  //       imageUrl: images['premium-twin-tent-3'],
  //     },
  //   ],
  //   path: PageRoutes.premiumTwinRoom,
  //   isPrivate: true,
  // },
  [TentTypeV1.TWIN_ROOM]: {
    tentType: TentTypeV1.TWIN_ROOM,
    imageUrl: images['twin-tent-v3-1'],
    title: 'camp67-accommodation.twin-room.title',
    subtitle: 'camp67-accommodation.twin-room.subtitle',
    description: 'camp67-accommodation.twin-room.description',
    imageAlt: 'camp67-accommodation.twin-room.image-alt',
    guests: 'camp67-accommodation.twin-room.guests',
    people: 2,
    price: getPriceTag(TentTypeV1.TWIN_ROOM, false),
    bathingPrice: getBathingPriceTag(TentTypeV1.TWIN_ROOM, false),
    amenities: {
      tentAmenities: twinRoomAmenities.map((prev) => ({ ...prev, open: undefined })),
      campAmenities: campAmenities.map((prev) => ({ ...prev, open: undefined })),
    },
    gallery: [
      {
        imageUrl: images['twin-tent-v3-1'],
      },
      {
        imageUrl: images['twin-tent-v3-2'],
      },
      {
        imageUrl: images['twin-tent-v3-3'],
      },
    ],
    path: PageRoutes.twinRoom,
    isPrivate: true,
  },
  [TentTypeV1.FAMILY_ROOM]: {
    tentType: TentTypeV1.FAMILY_ROOM,
    imageUrl: images['family-tent-v3-1'],
    title: 'camp67-accommodation.family-room.title',
    subtitle: 'camp67-accommodation.family-room.subtitle',
    description: 'camp67-accommodation.family-room.description',
    imageAlt: 'camp67-accommodation.family-room.image-alt',
    guests: 'camp67-accommodation.family-room.guests',
    people: 4,
    price: getPriceTag(TentTypeV1.FAMILY_ROOM, false),
    bathingPrice: getBathingPriceTag(TentTypeV1.FAMILY_ROOM, false),
    amenities: {
      tentAmenities: familyRoomAmenities.map((prev) => ({ ...prev, open: undefined })),
      campAmenities: campAmenities.map((prev) => ({ ...prev, open: undefined })),
    },
    gallery: [
      {
        imageUrl: images['family-tent-v3-1'],
      },
      {
        imageUrl: images['family-tent-v3-2'],
      },
      {
        imageUrl: images['family-tent-v3-3'],
      },
    ],
    path: PageRoutes.familyRoom,
    isPrivate: true,
  },
  [TentTypeV1.PRIVATE_GROUP_COTTAGE]: {
    tentType: TentTypeV1.PRIVATE_GROUP_COTTAGE,
    imageUrl: images['dorm-singles-v3-1'],
    title: 'camp67-accommodation.private-group-cottage.title',
    subtitle: 'camp67-accommodation.private-group-cottage.subtitle',
    description: 'camp67-accommodation.private-group-cottage.description',
    imageAlt: 'camp67-accommodation.private-group-cottage.image-alt',
    guests: 'camp67-accommodation.private-group-cottage.guests',
    people: 8,
    price: getPriceTag(TentTypeV1.PRIVATE_GROUP_COTTAGE, false),
    bathingPrice: getBathingPriceTag(TentTypeV1.PRIVATE_GROUP_COTTAGE, false),
    amenities: {
      tentAmenities: dormAmenities(TentTypeV1.PRIVATE_GROUP_COTTAGE).map((prev) => ({
        ...prev,
        open: undefined,
      })),
      campAmenities: campAmenities.map((prev) => ({ ...prev, open: undefined })),
    },
    gallery: [
      {
        imageUrl: images['dorm-singles-v3-1'],
      },
      {
        imageUrl: images['dorm-singles-v3-3'],
      },
    ],
    path: PageRoutes.privateGroup,
    isPrivate: true,
  },
  [TentTypeV1.DORM_SINGLE]: {
    tentType: TentTypeV1.DORM_SINGLE,
    imageUrl: images['dorm-singles-v3-1'],
    title: 'camp67-accommodation.dorm-single.title',
    subtitle: 'camp67-accommodation.dorm-single.subtitle',
    description: 'camp67-accommodation.dorm-single.description',
    imageAlt: 'camp67-accommodation.dorm-single.image-alt',
    guests: 'camp67-accommodation.dorm-single.guests',
    people: 1,
    price: getPriceTag(TentTypeV1.DORM_SINGLE, false),
    bathingPrice: getBathingPriceTag(TentTypeV1.DORM_SINGLE, false),
    amenities: {
      tentAmenities: dormAmenities(TentTypeV1.DORM_SINGLE).map((prev) => ({
        ...prev,
        open: undefined,
      })),
      campAmenities: campAmenities.map((prev) => ({ ...prev, open: undefined })),
    },
    gallery: [
      {
        imageUrl: images['dorm-singles-v3-1'],
      },
      {
        imageUrl: images['dorm-singles-v3-3'],
      },
    ],
    path: PageRoutes.dormSingle,
    isPrivate: false,
  },
  [TentTypeV1.DORM_BUNK]: {
    tentType: TentTypeV1.DORM_BUNK,
    imageUrl: images['dorm-bunks-v2'],
    title: 'camp67-accommodation.dorm-bunk.title',
    subtitle: 'camp67-accommodation.dorm-bunk.subtitle',
    description: 'camp67-accommodation.dorm-bunk.description',
    imageAlt: 'camp67-accommodation.dorm-bunk.image-alt',
    guests: 'camp67-accommodation.dorm-bunk.guests',
    people: 2,
    price: getPriceTag(TentTypeV1.DORM_BUNK, false),
    bathingPrice: getBathingPriceTag(TentTypeV1.DORM_BUNK, false),
    amenities: {
      tentAmenities: dormAmenities(TentTypeV1.DORM_BUNK).map((prev) => ({
        ...prev,
        open: undefined,
      })),
      campAmenities: campAmenities.map((prev) => ({ ...prev, open: undefined })),
    },
    gallery: [
      {
        imageUrl: images['dorm-bunks-v2'],
      },
      {
        imageUrl: images['dorm-bunks-v2-2'],
      },
      {
        imageUrl: images['dorm-bunks-v2-3'],
      },
      {
        imageUrl: images['dorm-singles-v3-3'],
      },
    ],
    path: PageRoutes.dormBunk,
    isPrivate: false,
  },
};
